const { useTranslation } = require('react-i18next');
const { Row, Col, FormGroup, Label, Input } = require('reactstrap');

const PrivacyAndPolicy = ({ changeValue, defaultValue }) => {
  const { t, ready } = useTranslation(['auth']);
  if (!ready) return null;
  return (
    <Row>
      <Col className="col-12 mt-1 mx-auto">
        <FormGroup check>
          <Label check>
            <Input
              defaultChecked={defaultValue}
              onChange={(e) => {
                changeValue(e.target.checked);
              }}
              type="checkbox"
            />
            {t('auth:signUpMainPrivacyTermsText')}
            <a
              href={
                localStorage.getItem('region') === 'usa'
                  ? 'https://getflowshare.com/general-terms-and-conditions-us'
                  : t('auth:termsAndConditions')
              }
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none' }}
            >
              <span className="text-underline">
                {t('auth:signUpMainTermsAndService')}
              </span>
            </a>
            {` ${t('global:and')} `}
            <a
              href={
                localStorage.getItem('region') === 'usa'
                  ? 'https://getflowshare.com/privacy-policy-us/'
                  : t('auth:privacyAndPolicy')
              }
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none' }}
            >
              <span className="text-underline">
                {t('auth:signUpMainPrivatePolicy')}
              </span>
            </a>
          </Label>
        </FormGroup>
      </Col>
    </Row>
  );
};

export default PrivacyAndPolicy;
