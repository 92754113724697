import NOTIFICATION_TYPES from 'constants/notificationType';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  Card,
  List,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Col,
  Button,
  Row,
  Label,
} from 'reactstrap';
import { organizationService } from 'services/organization.service';
import { openNotification } from 'utlis/notification';

import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SignUpBasic = ({ changeShowSignUp }) => {
  const { t, ready } = useTranslation(['auth', 'global', 'notifications']);
  const [email, setEmail] = useState('');

  const [readyToSend, setReadyToSend] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (
      email
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    ) {
      setReadyToSend(true);
    } else {
      setReadyToSend(false);
    }
  }, [email]);

  if (!ready) return null;

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    handleEmailSubmit();
    setLoading(false);
    setReadyToSend(false);
  };

  const getSource = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    return urlParams.get('source');
  };
  const handleEmailSubmit = async () => {
    try {
      await organizationService.addEmailInDirectory(email, getSource());
      changeShowSignUp(email);
    } catch (e) {
      openNotification(e.error, NOTIFICATION_TYPES.ERROR);
    }
  };

  return (
    <>
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardBody className="px-lg-5 py-lg-5">
            <h2>{t('auth:signUpBasicHeading')}</h2>
            <List type="unstyled">
              <li className="mt-4 d-flex">
                <FontAwesomeIcon
                  className="icon-container mt-1"
                  icon={faCircleCheck}
                  style={{ color: '#2db577' }}
                />
                <span className="ml-1 text-container">
                  {t('auth:signUpBasicFeatuerFree')}
                </span>
              </li>
              <li className="mt-1 d-flex">
                <FontAwesomeIcon
                  className="icon-container mt-1"
                  icon={faCircleCheck}
                  style={{ color: '#2db577' }}
                />
                <span className="ml-1 text-container">
                  {t('auth:signUpBasicFeatureUnlimitedGuides')}
                </span>
              </li>
              <li className="mt-1 d-flex">
                <FontAwesomeIcon
                  className="icon-container mt-1"
                  icon={faCircleCheck}
                  style={{ color: '#2db577' }}
                />
                <span className="ml-1 text-container">
                  {t('auth:signUpBasicFlowShareWindows')}
                </span>
              </li>
            </List>
            <Form onSubmit={handleSubmit}>
              <FormGroup className="mb-3">
                <Label> {t('auth:signUpBasicEmailLabel')}</Label>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>

                  <Input
                    className="pl-1"
                    placeholder={t('auth:signUpBasicEmailPlaceHolder')}
                    type="email"
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    defaultValue={email}
                    required
                  />
                </InputGroup>
              </FormGroup>

              <div className="text-center">
                <Button disabled={!readyToSend} type="submit" color={'primary'}>
                  {t('auth:signUpBasicButton')}
                  {loading && (
                    <span className="spinner-border spinner-border-sm" />
                  )}
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
        <Row className="mt-1 text-center" style={{ color: 'white' }}>
          <Col className="mx-auto" xs="6">
            <small>
              <Link
                style={{ color: 'white' }}
                as={'li'}
                to={`/auth/login/`}
                className="text-underline"
              >
                {`${t('global:signIn')}`}
              </Link>
            </small>
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default SignUpBasic;
