import {
  faPeopleGroup,
  faFileInvoiceDollar,
  faBookOpen,
  faCartShopping,
  faBullhorn,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PropTypes } from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { NavLink as NavLinkRRD, Link } from 'react-router-dom';
import {
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Row,
  Col,
} from 'reactstrap';
import { userService } from 'services/user.service';

import Routes from '../routes/routes';
import PLAN from 'constants/planType';
import PlanDetailsCard from './PlanDetailsCard';

const Sidebar = (props) => {
  const { t, ready } = useTranslation(['global']);

  const organizationMembership = useSelector(
    (state) => state.organizationMembership
  );
  const organization = useSelector((state) => state.organization);

  const [collapseOpen, setCollapseOpen] = useState();
  if (!ready) return null;

  const toggleCollapse = () => {
    setCollapseOpen((data) => !data);
  };

  const closeCollapse = () => {
    setCollapseOpen(false);
  };

  const { logo } = props;
  let navbarBrandProps;
  if (logo && logo.innerLink) {
    navbarBrandProps = {
      to: logo.innerLink,
      tag: Link,
    };
  } else if (logo && logo.outterLink) {
    navbarBrandProps = {
      href: logo.outterLink,
      target: '_blank',
    };
  }

  return (
    <Navbar
      className="navbar-vertical fixed-left navbar-light bg-white"
      expand="md"
      id="sidenav-main"
    >
      {/* Toggler */}
      <button className="navbar-toggler" type="button" onClick={toggleCollapse}>
        <span className="navbar-toggler-icon" />
      </button>
      {/* Brand */}
      {logo ? (
        <NavbarBrand
          className="pt-0"
          style={{ marginLeft: '-37px' }}
          {...navbarBrandProps}
        >
          <img
            alt={logo.imgAlt}
            className="navbar-brand-img"
            src={logo.imgSrc}
          />
        </NavbarBrand>
      ) : null}

      {/* Collapse */}
      <Collapse navbar isOpen={collapseOpen}>
        {/* Collapse header */}
        <div className="navbar-collapse-header d-md-none">
          <Row>
            {logo ? (
              <Col className="collapse-brand" xs="6">
                {logo.innerLink ? (
                  <Link to={logo.innerLink}>
                    <img alt={logo.imgAlt} src={logo.imgSrc} />
                  </Link>
                ) : (
                  <a href={logo.outterLink}>
                    <img alt={logo.imgAlt} src={logo.imgSrc} />
                  </a>
                )}
              </Col>
            ) : null}
            <Col className="collapse-close" xs="6">
              <button
                className="navbar-toggler"
                type="button"
                onClick={toggleCollapse}
              >
                <span />
                <span />
              </button>
            </Col>
          </Row>
        </div>
        <Nav navbar>
          <NavItem>
            <NavLink
              to={Routes.app.dashboard}
              tag={NavLinkRRD}
              onClick={closeCollapse}
              end
            >
              <i className={'ni ni-tv-2'} style={{ color: '#15A8B8' }} />
              {t('global:dashboard')}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              href={'/flowshare-assist'}
              onClick={() => {
                closeCollapse();
                window.location.href = '/flowshare-assist';
              }}
            >
              <i className={'ni ni-chat-round'} style={{ color: '#15A8B8' }} />

              {t('global:flowShareAssist')}
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              to={Routes.app.manageFlows}
              tag={NavLinkRRD}
              onClick={closeCollapse}
            >
              <i style={{ color: '#15A8B8' }}>
                <FontAwesomeIcon icon={faBookOpen} />
              </i>
              {t('global:flowLibrary')}
            </NavLink>
          </NavItem>
        </Nav>
        <hr className="my-3" />
        <h6 className="navbar-heading text-muted">
          {t('global:personalSettings')}
        </h6>
        <Nav className="mb-md-3" navbar>
          <NavItem>
            <NavLink
              to={Routes.app.userProfile}
              tag={NavLinkRRD}
              onClick={closeCollapse}
            >
              <i className={'ni ni-single-02'} style={{ color: '#15A8B8' }} />
              {t('global:profile')}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              to={Routes.app.referral}
              tag={NavLinkRRD}
              onClick={closeCollapse}
            >
              <i style={{ color: '#15A8B8' }}>
                <FontAwesomeIcon icon={faBullhorn} />
              </i>
              {t('global:referrals')}
            </NavLink>
          </NavItem>
        </Nav>
        {(organizationMembership.roleName === 'admin' ||
          organizationMembership.roleName === 'author') && (
          <>
            <hr className="my-3" />
            <h6 className="navbar-heading text-muted">
              {t('global:organizationSettings')}
            </h6>

            <Nav className="mb-md-3" navbar>
              <NavItem>
                <NavLink
                  to={Routes.app.manageLicenses}
                  tag={NavLinkRRD}
                  onClick={closeCollapse}
                >
                  <i className={'ni ni-key-25'} style={{ color: '#15A8B8' }} />
                  {t('global:manageLicenseKey')}
                </NavLink>
              </NavItem>

              {organizationMembership.roleName === 'admin' && (
                <>
                  <NavItem>
                    <NavLink
                      to={Routes.app.manageMembers}
                      tag={NavLinkRRD}
                      onClick={closeCollapse}
                    >
                      <i style={{ color: '#15A8B8' }}>
                        <FontAwesomeIcon icon={faPeopleGroup} />
                      </i>
                      {t('global:manageMembers')}
                    </NavLink>
                  </NavItem>

                  {organization.plan !== PLAN.FREE && (
                    <NavItem>
                      <NavLink
                        to={Routes.app.billing}
                        tag={NavLinkRRD}
                        onClick={closeCollapse}
                      >
                        <i>
                          <FontAwesomeIcon
                            icon={faFileInvoiceDollar}
                            style={{ color: '#15A8B8' }}
                          />
                        </i>

                        {t('global:billing')}
                      </NavLink>
                    </NavItem>
                  )}
                  {organization.plan !== PLAN.ENTERPRISE && (
                    <NavItem>
                      <NavLink
                        to={Routes.app.managePlans}
                        tag={NavLinkRRD}
                        onClick={closeCollapse}
                      >
                        <i>
                          <FontAwesomeIcon
                            icon={faCartShopping}
                            style={{ color: '#15A8B8' }}
                          />
                        </i>

                        {t('global:plans')}
                      </NavLink>
                    </NavItem>
                  )}
                </>
              )}
            </Nav>
          </>
        )}
        {organization.plan === PLAN.FREE &&
          organizationMembership.roleName !== 'chatter' && <PlanDetailsCard />}
        <NavItem
          style={{
            backgroundColor: 'transparent',
            flex: '1',
            display: 'flex',
            alignItems: 'flex-end',
          }}
          className="d-flex justify-content-center"
        >
          <NavLink>
            <Link
              onClick={() => {
                userService.logout(true);
              }}
              style={{ color: 'black', textDecoration: 'underline' }}
              className="mt-2"
            >
              {t('global:logout')}
            </Link>
          </NavLink>
        </NavItem>
      </Collapse>
    </Navbar>
  );
};

Sidebar.propTypes = {
  logo: PropTypes.shape({
    innerLink: PropTypes.string,
    outterLink: PropTypes.string,
    imgSrc: PropTypes.string.isRequired,
    imgAlt: PropTypes.string.isRequired,
  }),
};

export default Sidebar;
