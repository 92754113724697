import { useState, useEffect } from 'react';
import { Container } from 'reactstrap';

const Footer = () => {
  const [region, setRegion] = useState('europe');

  useEffect(() => {
    const storedRegion = localStorage.getItem('region');
    const regionQueryParameter = getRegionFromQueryParameter();
    if (regionQueryParameter) {
      setRegion(regionQueryParameter);
    } else if (storedRegion) {
      setRegion(storedRegion);
    } else {
      // If region is not set, determine user's location
      fetch('https://ipapi.co/json/')
        .then((response) => response.json())
        .then((data) => {
          let detectedRegion = 'other';
          if (['US', 'CA'].includes(data.country_code)) {
            detectedRegion = 'usa';
          } else if (data.continent_code === 'EU') {
            detectedRegion = 'europe';
          }
          localStorage.setItem('region', detectedRegion);
          setRegion(detectedRegion);
        })
        .catch((error) => {
          console.error('Error fetching location:', error);
          localStorage.setItem('region', 'other');
          setRegion('other');
        });
    }
  }, []);

  const refreshPage = (newRegion) => {
    const url = new URL(window.location.href);

    url.searchParams.set('region', newRegion);

    window.location.assign(url.toString());
  };

  const getRegionFromQueryParameter = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    return urlParams.get('region');
  };

  const handleRegionClick = (selectedRegion) => {
    localStorage.setItem('region', selectedRegion);
    setRegion(selectedRegion);
  };

  return (
    <Container fluid className="d-flex justify-content-center text-white mb-2">
      {/* USA/Canada */}
      <span
        style={{
          fontWeight: region === 'usa' ? 'bold' : 'normal',
          textDecoration: region === 'usa' ? 'none' : 'underline',
          cursor: 'pointer',
        }}
        onClick={() => {
          handleRegionClick('usa');
          refreshPage('usa');
        }}
      >
        USA/Canada
      </span>
      <span className="ml-1 mr-1">{'·'}</span>
      {/* Europe */}
      <span
        style={{
          fontWeight: region === 'europe' ? 'bold' : 'normal',
          textDecoration: region === 'europe' ? 'none' : 'underline',
          cursor: 'pointer',
        }}
        onClick={() => {
          handleRegionClick('europe');
          refreshPage('europe');
        }}
      >
        Europe
      </span>
      <span className="ml-1 mr-1">{'·'}</span>
      {/* Other */}
      <span
        style={{
          fontWeight: region === 'other' ? 'bold' : 'normal',
          textDecoration: region === 'other' ? 'none' : 'underline',
          cursor: 'pointer',
        }}
        onClick={() => {
          handleRegionClick('other');
          refreshPage('other');
        }}
      >
        Other
      </span>
      <span className="ml-1 mr-1">{'·'}</span>
      {region !== 'usa' && '©2025 miraminds GmbH'}
      {region === 'usa' && '©2025 miraminds, Inc.'}
    </Container>
  );
};

export default Footer;
