import PLAN from 'constants/planType';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Col, Card, CardBody, Button, List, CardHeader } from 'reactstrap';

import PlanMainText from './PlanMainText';
import PlanPrice from './PlanPrice';

const ProfessionalPlan = ({
  showMonthlyPrice,
  generateCustomerCheckoutSession,
  loading,
}) => {
  const { t, ready } = useTranslation(['plans']);
  const planData = {
    planName: t('plans:professionalPlanData.planName'),
    singleMonthlyPriceUSD: '$45',
    singleYearlyPriceUSD: '$40',
    singleMonthlyPriceEUR: '€42',
    singleYearlyPriceEUR: '€37,50',

    features: [
      { text: t('plans:professionalPlanData.features.customBranding') },
      {
        text: t('plans:professionalPlanData.features.localExportFormats'),
      },
      { text: t('plans:professionalPlanData.features.embedFlows') },
      { text: t('plans:professionalPlanData.features.manageFlows') },
      { text: t('plans:professionalPlanData.features.flowShareAssist') },
    ],
  };

  const getPrice = () => {
    // Determine the price based on planType, showMonthlyPrice, and organization.region
    const price = showMonthlyPrice
      ? organization.region === 'usa'
        ? planData.singleMonthlyPriceUSD
        : planData.singleMonthlyPriceEUR
      : organization.region === 'usa'
      ? planData.singleYearlyPriceUSD
      : planData.singleYearlyPriceEUR;

    return `${price}*`;
  };

  const [priceAnimation, setPriceAnimation] = useState('');
  const organization = useSelector((state) => state.organization);
  const [price, setPrice] = useState(getPrice());

  useEffect(() => {
    setPriceAnimation('price-fade-out');

    const timer = setTimeout(() => {
      setPriceAnimation('price-fade-in');
      setPrice(getPrice());
    }, 300); // This duration should match the fadeOut animation time

    return () => clearTimeout(timer);
  }, [showMonthlyPrice]);

  if (!ready) return null;

  const handleClick = () => {
    const priceId = showMonthlyPrice
      ? organization.region === 'usa'
        ? process.env.REACT_APP_SINGLE_MONTHLY_PRICE_INC
        : process.env.REACT_APP_SINGLE_MONTHLY_PRICE_GLOBAL
      : organization.region === 'usa'
      ? process.env.REACT_APP_SINGLE_YEARLY_PRICE_INC
      : process.env.REACT_APP_SINGLE_YEARLY_PRICE_GLOBAL;

    generateCustomerCheckoutSession(priceId);
  };

  return (
    <Col xs="12" sm="8" md="7" lg="4">
      <Card className="border-0 border-bottom border-primary shadow-sm">
        <CardHeader
          className="plan-card-header"
          style={{ backgroundColor: '#30BDC0' }}
        >
          <h2 style={{ color: 'white' }}>{planData.planName}</h2>
        </CardHeader>
        <CardBody className="p-4 p-xxl-5 plan-body-height">
          <PlanMainText text={t('plans:professionalPlanData.mainHeading')} />
          <hr className="my-3" />
          <div className={`ml-3 card-nested-body`}>
            <div className={`${priceAnimation}`}>
              <PlanPrice price={price} />
            </div>
            <Button
              onClick={handleClick}
              color="primary"
              className="rounded-pill plan-button"
              disabled={
                organization.plan === PLAN.PROFESSIONAL ||
                organization.plan === PLAN.ENTERPRISE ||
                loading
              }
            >
              {t('plans:plansChoosePlanButton')}

              {loading && <span className="spinner-border spinner-border-sm" />}
            </Button>
          </div>

          <hr className="my-3 mt--3" />
          <h4>{t('plans:professionalPlanData.featuresHeading')}</h4>
          <List type="unstyled">
            {planData.features.map((feature, index) => (
              <li key={index} className="mt-2 d-flex align-items-start">
                <div className="icon-container">
                  {feature.available ? (
                    <i className="fas fa-times"></i>
                  ) : (
                    <i className="fas fa-check"></i>
                  )}
                </div>
                <span className="text-container">{feature.text}</span>
              </li>
            ))}
          </List>
        </CardBody>
      </Card>
    </Col>
  );
};

export default ProfessionalPlan;
