import { useSelector } from 'react-redux';
import { Card, CardBody, Button, Progress } from 'reactstrap';
import PLAN from 'constants/planType';
import Routes from 'routes/routes';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import detectBrowserLanguage from 'detect-browser-language';

const PlanDetailsCard = () => {
  let browserLanguage = detectBrowserLanguage();
  browserLanguage = browserLanguage.split('-')[0];

  const emailContent = {
    en: `Hi,

While using the FlowShare Free version, I've encountered some limitations that are hindering our productivity.
The Pro version offers features that would greatly benefit our work.

URL: [https://getflowshare.com](https://getflowshare.com/)

Could we please consider upgrading to the paid plan to enhance our efficiency?

Thank you for considering this request.

Best regards,
  `,
    de: `Hi,

While using the FlowShare Free version, I've encountered some limitations that are hindering our productivity.
The Pro version offers features that would greatly benefit our work.

URL: [https://getflowshare.com](https://getflowshare.com/)

Could we please consider upgrading to the paid plan to enhance our efficiency?

Thank you for considering this request.

Best regards,
`,
  };

  const { t, ready } = useTranslation(['global']);
  if (!ready) return null;
  const organization = useSelector((state) => state.organization);
  const organizationMembership = useSelector(
    (state) => state.organizationMembership
  );

  const flows = useSelector((state) => state.flows);

  return (
    <Card className="plan-card-details">
      <CardBody className=" p-1 p-2">
        {organization.plan === PLAN.FREE && (
          <div className="mb-2">
            <div className="mb-2">{`FlowShare ${t('global:free')}`}</div>
          </div>
        )}

        {(organization.plan === PLAN.PROFESSIONAL ||
          organization.plan === PLAN.ENTERPRISE) && (
          <div className="mb-2">{`FlowShare ${t('global:professional')}`}</div>
        )}
        {organization.plan === PLAN.FREE && (
          <>
            <Progress
              className="mb-2"
              value={flows.flowsCount}
              max={organization.flowsUploadLimit}
            />
            <div className="mb-2">
              {`Flows ${t('global:shared')} ${flows.flowsCount} of ${
                organization.flowsUploadLimit
              }`}
            </div>
            {organizationMembership.roleName === 'author' && (
              <Button
                color="primary"
                onClick={() => {
                  window.location.href = `mailto:${encodeURIComponent(
                    organization.email
                  )}?subject=${encodeURIComponent(
                    browserLanguage === 'de'
                      ? 'FlowShare zu berücksichtigen'
                      : 'FlowShare to consider'
                  )}&body=${encodeURIComponent(
                    browserLanguage === 'de' ? emailContent.de : emailContent.en
                  )}`;
                }}
                block
              >
                {t('global:upgradePlanButton')}
              </Button>
            )}
            {organizationMembership.roleName === 'admin' && (
              <Link to={Routes.app.managePlans}>
                <Button color="primary" block>
                  {t('global:upgradePlanButton')}
                </Button>
              </Link>
            )}
          </>
        )}
      </CardBody>
    </Card>
  );
};

export default PlanDetailsCard;
