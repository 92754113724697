import axios from 'axios';
import detectBrowserLanguage from 'detect-browser-language';
import { handleError } from 'utlis/errorHandler';
import { getAuthHeader } from 'utlis/getAuthHeader';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
let browserLanguage = detectBrowserLanguage();
browserLanguage = browserLanguage.split('-')[0];

export const organizationService = {
  getCurrentOrganization,
  getStripeSession,
  addEmailInDirectory,
  registration,
  generateCustomerCheckoutSession,
  sendFeedbackEmail,
  deleteOrganization,
  updateOrganization,
};

function updateOrganization(email, options = {}) {
  const { useCase = null, name = null, phone = null } = options;

  const requestConfig = {
    headers: getAuthHeader(browserLanguage),
  };

  const data = {
    email: email,
    useCase: useCase,
    name: name,
    phone: phone,
  };

  return axios
    .put(`${BACKEND_URL}/api/organization`, data, requestConfig)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return handleError(error);
    });
}

function deleteOrganization() {
  const requestConfig = {
    headers: getAuthHeader(browserLanguage),
  };

  return axios
    .delete(`${BACKEND_URL}/api/organization`, requestConfig)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return handleError(error);
    });
}

function sendFeedbackEmail(email, text) {
  return axios
    .post(`${BACKEND_URL}/api/organization/feedback`, {
      email: email,
      text: text,
    })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return handleError(error);
    });
}
function generateCustomerCheckoutSession(id, priceId) {
  const requestConfig = {
    headers: getAuthHeader(browserLanguage),
  };

  return axios
    .get(
      `${BACKEND_URL}/api/organization/customer-checkout-session/${id}/${priceId}`,
      requestConfig
    )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return handleError(error);
    });
}
function registration(email, name, password, referrarCode, region) {
  const requestConfig = {
    headers: getAuthHeader(browserLanguage),
  };

  const data = {
    email: email,
    name: name,
    password: password,
    source: window.location.href,
    referrarCode: referrarCode,
    region: region === 'usa' ? 'usa' : 'global',
  };

  return axios
    .post(`${BACKEND_URL}/api/auth/registration`, data, requestConfig)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return handleError(error);
    });
}

function addEmailInDirectory(email, source) {
  const data = { email: email, source: source };
  const requestConfig = {
    headers: getAuthHeader(browserLanguage),
  };

  const path = `${BACKEND_URL}/api/organization/incomplete-registration-directory`;

  return axios
    .post(path, data, requestConfig)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return handleError(error);
    });
}

function getStripeSession(id) {
  const requestConfig = {
    headers: getAuthHeader(browserLanguage),
  };

  return axios
    .get(
      `${BACKEND_URL}/api/organization/customer-portal-session/${id}`,
      requestConfig
    )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return handleError(error);
    });
}

function getCurrentOrganization() {
  const requestConfig = {
    headers: getAuthHeader(browserLanguage),
  };

  return axios
    .get(`${BACKEND_URL}/api/organization/current-organization`, requestConfig)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return handleError(error);
    });
}
