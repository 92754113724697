import Header from 'components/Header.js';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from 'reactstrap';

import dashboardVideo from '../assets/videos/customer-portal-welcome-video-1.mp4';
import { setJustLoggedIn } from '../redux/actions/misc.actions';
import routes from '../routes/routes';
import dashboardDownloadIcon from '../assets/img/icons/dashboard_download.svg';
import dashboarInviteIcon from '../assets/img/icons/dashboard_invite.svg';
import dashboardSupportIcon from '../assets/img/icons/dashboard_support.svg';
import DashboardWidget from './DashboardWidget';
import ResponsiveVideoCard from './VideoComponent';

const Index = () => {
  const { t, ready } = useTranslation(['index', 'global']);
  const dispatch = useDispatch();
  const misc = useSelector((state) => state.misc);
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();
  const [openPopUp, setOpenPopUp] = useState(false);

  useEffect(() => {
    document.body.style.backgroundColor = '#eef8ff';
  }, []);
  useEffect(() => {
    if (misc.justLoggedIn && user.firstName === '' && user.lastName === '') {
      setOpenPopUp(true);
    } else if (misc.justLoggedIn) {
      dispatch(setJustLoggedIn(false));
    }
  });

  if (!ready) return null;

  const toggle = () => {
    dispatch(setJustLoggedIn(false));
    setOpenPopUp(false);
  };

  const handleClick = () => {
    setJustLoggedIn(false);
    navigate(routes.app.userProfile);
  };

  return (
    <>
      <Header activeLocation={t('index:dashboard')} />
      <Container className="mt-lg--5 mt-md--5 mt-sm--3 mt--3" fluid>
        <Row>
          <Col
            className="d-flex flex-row flex-below-1228"
            style={{ gap: '1rem' }}
          >
            <DashboardWidget
              header={t('index:downloadFlowShareWidgetHeader')}
              text={t('index:downloadFlowShareText')}
              link={t('index:downloadLink')}
              buttonText={t('index:downloadFlowShareButtonText')}
              iconLink={dashboardDownloadIcon}
            />

            <DashboardWidget
              header={t('inviteUserWidgetHeader')}
              text={t('index:inviteUserWidgetText')}
              link={routes.app.manageMembers}
              buttonText={t('global:manageMembers')}
              iconLink={dashboarInviteIcon}
            />

            <DashboardWidget
              header={t('index:getSupportWidgetHeader')}
              text={t('index:getSupportText')}
              link={t('global:suportLink')}
              buttonText={t('index:getSupportButtonText')}
              iconLink={dashboardSupportIcon}
            />
          </Col>
        </Row>

        <ResponsiveVideoCard
          headerText={t('index:cardHeaderText')}
          videoSrc={dashboardVideo}
        />

        <Modal isOpen={openPopUp} centered size="m" toggle={toggle}>
          <ModalHeader>
            <span style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
              {t('index:popUpHeaderText')}
            </span>
          </ModalHeader>
          <ModalBody
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <div>{t('index:popUpMainText')}</div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: 'auto',
                gap: '19px',
              }}
            >
              <a href={'#'} style={{ marginTop: '10px' }} onClick={toggle}>
                {t('index:popUpCancelButton')}
              </a>
              <Button
                color="primary"
                className="btn-primary"
                onClick={handleClick}
              >
                {t('index:popUpOkButton')}
              </Button>
            </div>
          </ModalBody>
        </Modal>
      </Container>
    </>
  );
};

export default Index;
