import useGoogleTagManager from 'components/hook/GoogleTagManager';
import PostSignupMessage from './PostSignupMessaage';
import SignUpNavbar from './SignUpNavbar';

const RegistrationSuccessfull = () => {
  useGoogleTagManager('GTM-KJ6CVXZ6');
  return (
    <>
      <SignUpNavbar />
      <PostSignupMessage />
    </>
  );
};

export default RegistrationSuccessfull;
