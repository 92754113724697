import axios from 'axios';
import detectBrowserLanguage from 'detect-browser-language';
import { handleError } from 'utlis/errorHandler';
import { getAuthHeader } from 'utlis/getAuthHeader';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
let browserLanguage = detectBrowserLanguage();
browserLanguage = browserLanguage.split('-')[0];
export const userService = {
  login,
  authorization,
  logout,
  getUserProfile,
  resetPassword,
  forgotPassword,
  changePassword,
  updateAccount,
  firstLoginAccountSetup,
  refreshToken,
  verifyEmail,
  qtLogin,
  microsoftAuthorization,
};

async function microsoftAuthorization(idToken, loginType, url, region) {
  const requestConfig = {
    headers: getAuthHeader(browserLanguage),
  };

  const requestBody = {
    idToken: idToken,
    loginType: loginType,
    url: url,
    region: region === 'usa' ? 'usa' : 'global',
  };

  return axios
    .post(
      `${BACKEND_URL}/api/auth/microsoft/authorize`,
      requestBody,
      requestConfig
    )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return handleError(error, false);
    });
}

async function qtLogin(username, password) {
  const requestConfig = {
    headers: getAuthHeader(browserLanguage),
  };

  const requestBody = {
    username: username,
    password: password,
  };

  return axios
    .post(`${BACKEND_URL}/api/auth/qt-login`, requestBody, requestConfig)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return handleError(error, false);
    });
}

async function verifyEmail(token) {
  const requestConfig = {
    headers: getAuthHeader(browserLanguage),
  };

  return axios
    .get(`${BACKEND_URL}/api/users/verify-email/${token}`, requestConfig)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return handleError(error, false);
    });
}

async function refreshToken(refreshToken) {
  const requestBody = {
    refreshToken: refreshToken,
  };

  return axios
    .post(`${BACKEND_URL}/api/auth/refresh-token`, requestBody)
    .then((res) => {
      return res.data;
    })
    .catch(() => {
      this.logout();
    });
}

async function authorization(
  username,
  password,
  client,
  redirectURI,
  responseType,
  scope,
  state
) {
  const requestConfig = {
    headers: getAuthHeader(browserLanguage),
  };

  const requestBody = {
    username: username,
    password: password,
    client: client,
    redirectURI: redirectURI,
    responseType: responseType,
    scope: scope,
    state: state,
  };

  return axios
    .post(`${BACKEND_URL}/api/auth/authorize`, requestBody, requestConfig)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return handleError(error, false);
    });
}
async function firstLoginAccountSetup(newPassword, firstName, lastName) {
  const requestConfig = {
    headers: getAuthHeader(browserLanguage),
  };

  const data = {
    newPassword: newPassword,
    firstName: firstName,
    lastName: lastName,
  };

  return axios
    .put(`${BACKEND_URL}/api/user/set-up-account`, data, requestConfig)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return handleError(error);
    });
}
async function updateAccount(firstName, lastName, email, position, id) {
  const requestConfig = {
    headers: getAuthHeader(browserLanguage),
  };

  const data = {
    firstName: firstName,
    lastName: lastName,
    email: email,
    position: position,
  };

  return axios
    .put(`${BACKEND_URL}/api/user/${id}`, data, requestConfig)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return handleError(error);
    });
}

async function changePassword(currentPassword, newPassword) {
  const requestConfig = {
    headers: getAuthHeader(browserLanguage),
  };

  const data = {
    currentPassword: currentPassword,
    newPassword: newPassword,
  };

  return axios
    .put(`${BACKEND_URL}/api/user/password-change`, data, requestConfig)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return handleError(error);
    });
}

async function forgotPassword(email) {
  const requestConfig = {
    headers: getAuthHeader(browserLanguage),
  };

  const data = {
    email: email,
  };

  return axios
    .post(`${BACKEND_URL}/api/user/password-forgot`, data, requestConfig)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return handleError(error);
    });
}

async function resetPassword(data) {
  const requestConfig = {
    headers: getAuthHeader(browserLanguage),
  };

  return axios
    .put(`${BACKEND_URL}/api/user/password-reset`, data, requestConfig)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return handleError(error);
    });
}

async function login(username, password) {
  const requestConfig = {
    headers: getAuthHeader(browserLanguage),
  };

  const requestBody = {
    username: username,
    password: password,
  };

  return axios
    .post(`${BACKEND_URL}/api/auth/login`, requestBody, requestConfig)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return handleError(error, false);
    });
}

function getUserProfile() {
  const requestConfig = {
    headers: getAuthHeader(browserLanguage),
  };

  return axios
    .get(`${BACKEND_URL}/api/user/user-account`, requestConfig)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return handleError(error);
    });
}

function logout(reloadPage = true) {
  // remove user from local storage to log user out
  if (localStorage) {
    localStorage.removeItem('POR');
    localStorage.removeItem('PORREF');
  }

  if (reloadPage === true) {
    window.location.reload();
  }
}
