import { Card, CardHeader, CardBody } from 'reactstrap';

const ResponsiveVideoCard = ({ headerText, videoSrc }) => {
  return (
    <div>
      <CardHeader className="bg-white border-0">
        <h4 className="card-header-title">{headerText}</h4>
      </CardHeader>
      <Card className="bg-secondary shadow h-100">
        <CardBody>
          <div className="video-container">
            <video className="responsive-video" title="dashboardVideo" controls>
              <source src={videoSrc} type="video/mp4" />
              Your browser does not support the video.
            </video>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default ResponsiveVideoCard;
