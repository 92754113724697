import { Button, Card, CardBody } from 'reactstrap';

const DashboardWidget = ({ header, text, link, buttonText, iconLink }) => {
  return (
    <Card className="bg-secondary shadow mb-4 " style={{ minHeight: '200px' }}>
      <CardBody className="pt-0">
        <div className="row wdiget-row">
          <div
            id="text"
            className="col-lg-8 col-md-12 col-12 order-2 order-lg-1 pr-0"
          >
            <h3 className="widget-title">{header}</h3>
            <div className="widget-text">{text}</div>
            <Button
              style={{ width: '200px' }}
              className="btn-primary-dashboard mt-3"
              href={link}
            >
              {buttonText}
            </Button>
          </div>
          <div className="widget-div-img-parent col-lg-4 col-md-12 col-12 order-1 order-lg-2 text-center mb-lg-0 pt-4">
            <img src={iconLink} className={'widget-img'} alt="icon" />
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default DashboardWidget;
