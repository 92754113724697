import NOTIFICATION_TYPES from 'constants/notificationType';
import { PASSWORD_CHANGE_REGEX } from 'constants/passwordChangeRegex';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import {
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Col,
  Button,
  Row,
  Label,
} from 'reactstrap';
import { organizationService } from 'services/organization.service';
import { openNotification } from 'utlis/notification';

import PasswordRequirements from 'components/PasswordRequirements';
import Routes from 'routes/routes';

const SignUpMain = ({ email }) => {
  const { t, ready } = useTranslation(['auth', 'global', 'notifications']);
  const navigate = useNavigate();

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [password, setPassword] = useState('');
  const [useCase, setUseCase] = useState('');
  const [phone, setPhone] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [readyToSend, setReadyToSend] = useState(false);
  const [loading, setLoading] = useState(false);

  const [acceptTerms, setAcceptTerms] = useState(false);

  useEffect(() => {
    if (
      lastName !== '' &&
      password !== '' &&
      password !== '' &&
      useCase !== '' &&
      confirmPassword !== '' &&
      new RegExp(`${PASSWORD_CHANGE_REGEX}`).test(password) &&
      password === confirmPassword
    ) {
      setReadyToSend(true);
    } else {
      setReadyToSend(false);
    }
  }, [lastName, password, confirmPassword, useCase]);

  if (!ready) return null;

  const handleSubmit = async () => {
    if (!acceptTerms) {
      openNotification(
        t('notifications:acceptTermsAndConditions'),
        NOTIFICATION_TYPES.ERROR
      );
      return;
    }

    setLoading(true);
    try {
      await organizationService.registration(
        email,
        lastName,
        password,
        firstName,
        getReferrarCode(),
        useCase,
        phone
      );

      openNotification(
        t('notifications:verificationEmailSentSuccess'),
        NOTIFICATION_TYPES.SUCCESS
      );

      // send the user to registration successful
      navigate(Routes.auth.registrationSuccessfull, {
        state: { fromSignup: true },
      });
    } catch (e) {
      openNotification(e.error, NOTIFICATION_TYPES.ERROR);
      setLoading(false);
      setReadyToSend(false);
    }
  };

  const getReferrarCode = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    return urlParams.get('referralCode');
  };

  return (
    <Col lg="8" md="9">
      <Card className="bg-secondary shadow border-0">
        <CardBody className="px-lg-5 py-lg-5">
          <h2>{t('auth:signUpMainHeading')}</h2>
          <Form>
            <FormGroup className="mb-3">
              <Label> {t('auth:signUpBasicEmailLabel')}</Label>
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-email-83" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  className="pl-1"
                  type="email"
                  disabled={true}
                  defaultValue={email}
                  required
                />
              </InputGroup>
            </FormGroup>

            <Row>
              <Col className="col-6">
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <Input
                      placeholder={`${t('global:firstNamePlaceHolderText')}`}
                      type="text"
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col className="col-6">
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <Input
                      placeholder={`${t('global:lastNamePlaceholderText')}*`}
                      type="text"
                      onChange={(e) => setLastName(e.target.value)}
                      required
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col className="col-6">
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <Input
                      type="select"
                      className="custom-select"
                      onChange={(e) => setUseCase(e.target.value)}
                      required
                    >
                      <option value="">
                        {`${t('auth:selectorHeading')}`}*
                      </option>
                      <option value="ERP-Project">
                        {`${t('auth:erpProject')}`}
                      </option>
                      <option value="SupportOrTraining">
                        {`${t('auth:supportOrTraining')}`}
                      </option>
                      <option value="HeyGen">{`${t('auth:heyGen')}`}</option>

                      <option value="Other"> {`${t('auth:other')}`}</option>
                    </Input>
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col className="col-6">
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <Input
                      placeholder={t('global:phone')}
                      type="text"
                      onChange={(e) => {
                        let value = e.target.value;
                        // Allow '+' only at the start and remove other non-numeric characters
                        if (value.startsWith('+')) {
                          value = '+' + value.slice(1).replace(/\D/g, '');
                        } else {
                          value = value.replace(/\D/g, '');
                        }
                        setPhone(value);
                      }}
                      value={phone}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col className="col-6">
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <Input
                      placeholder={`${t('global:password')}*`}
                      type="password"
                      onChange={(e) => setPassword(e.target.value)}
                      required
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col className="col-6">
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <Input
                      placeholder={`${t(
                        'auth:passwordResetConfirmNewPasswordPlaceHolder'
                      )}*`}
                      type="password"
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      required
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>

            <PasswordRequirements />

            <Row>
              <Col className="col-11 mt-3 mx-auto mb-3">
                <FormGroup check>
                  <Label check>
                    <Input
                      onChange={(e) => {
                        setAcceptTerms(e.target.checked);
                      }}
                      type="checkbox"
                    />
                    {t('auth:signUpMainPrivacyTermsText')}
                    <a
                      href={t('auth:termsAndConditions')}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: 'none' }}
                    >
                      <span className="text-underline">
                        {t('auth:signUpMainTermsAndService')}
                      </span>
                    </a>
                    {` ${t('global:and')} `}
                    <a
                      href={t('auth:privacyAndPolicy')}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: 'none' }}
                    >
                      <span className="text-underline">
                        {t('auth:signUpMainPrivatePolicy')}
                      </span>
                    </a>
                  </Label>
                </FormGroup>
              </Col>
            </Row>
            <div className="text-center">
              <Button
                disabled={!readyToSend}
                onClick={handleSubmit}
                color={'primary'}
              >
                {t('global:signUp')}
                {loading && (
                  <span className="spinner-border spinner-border-sm" />
                )}
              </Button>
            </div>
          </Form>
        </CardBody>
      </Card>
      <Row className="mt-1 text-center" style={{ color: 'white' }}>
        <Col className="mx-auto" xs="6">
          <small>
            {t('auth:signUpBasicLoginText')}
            <Link
              style={{ color: 'white' }}
              as={'li'}
              to={`/auth/login/}`}
              className="text-underline"
            >
              {` ${t('global:signIn')}`}
            </Link>
          </small>
        </Col>
      </Row>
    </Col>
  );
};

export default SignUpMain;
