import { useTranslation } from 'react-i18next';
import Header from './Header';
import { Col, Container, Row } from 'reactstrap';

const Referrals = () => {
  const { t, ready } = useTranslation(['global', 'notifications']);

  if (!ready) return null;
  return (
    <>
      <Header activeLocation={'referrals'} />
      <Container className={'mt-lg--5 mt-md--5 mt-sm--3 mt--3'} fluid>
        <Row className="p-0 m-0">
          <Col className="col-6">
            <form-widget ucid="Mef8ixw3qpfLv3C3GoTR3qOBw9E"></form-widget>
          </Col>
          <Col className="col-6">
            <rewards-widget ucid="Mef8ixw3qpfLv3C3GoTR3qOBw9E"></rewards-widget>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Referrals;
