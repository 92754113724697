import NOTIFICATION_TYPES from 'constants/notificationType';
import { PASSWORD_CHANGE_REGEX } from 'constants/passwordChangeRegex';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import {
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroup,
  Col,
  Button,
  Row,
  Label,
} from 'reactstrap';
import { organizationService } from 'services/organization.service';
import { openNotification } from 'utlis/notification';

import PasswordRequirements from 'components/PasswordRequirements';
import Routes from 'routes/routes';
import PrivacyAndPolicy from './PrivacyAndPolicy';
import { validateName } from 'utlis/utils';

const SignUpMain = ({ email }) => {
  const { t, ready } = useTranslation(['auth', 'global', 'notifications']);
  const navigate = useNavigate();

  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const [readyToSend, setReadyToSend] = useState(false);
  const [loading, setLoading] = useState(false);
  const [passwordMeetRequirements, setPasswordMeetRequirements] =
    useState(false);
  const [showPasswordRequirements, setShowPasswordRequirements] =
    useState(false);

  const [acceptTerms, setAcceptTerms] = useState(true);

  useEffect(() => {
    if (name !== '' && new RegExp(`${PASSWORD_CHANGE_REGEX}`).test(password)) {
      setReadyToSend(true);
    } else {
      setReadyToSend(false);
    }
  }, [name, password]);

  if (!ready) return null;

  const isPasswordRequirementsMet = (passwordValue) => {
    if (new RegExp(PASSWORD_CHANGE_REGEX).test(passwordValue)) {
      setShowPasswordRequirements(false);
    } else {
      setPasswordMeetRequirements(true);
      setShowPasswordRequirements(true);
    }
  };
  const handleSubmit = async () => {
    if (!acceptTerms) {
      openNotification(
        t('notifications:acceptTermsAndConditions'),
        NOTIFICATION_TYPES.ERROR
      );
      return;
    }

    if (!validateName(name)) {
      openNotification(
        t('notifications:validateName'),
        NOTIFICATION_TYPES.ERROR
      );
      return;
    }
    setLoading(true);
    try {
      await organizationService.registration(
        email,
        name,
        password,
        getReferrarCode(),
        localStorage.getItem('region')
      );

      openNotification(
        t('notifications:verificationEmailSentSuccess'),
        NOTIFICATION_TYPES.SUCCESS
      );

      // send the user to registration successful

      navigate(Routes.auth.registrationSuccessfull, {
        state: { fromSignup: true },
      });
    } catch (e) {
      openNotification(e.error, NOTIFICATION_TYPES.ERROR);
      setLoading(false);
      setReadyToSend(false);
    }
  };

  const getReferrarCode = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    return urlParams.get('referralCode');
  };

  return (
    <>
      <Card className="bg-secondary shadow border-0">
        <CardBody className="p-4">
          <Form>
            <FormGroup className="mb-3">
              <Label className="font-weight-bold  label">
                {t('auth:signUpBasicEmailLabel')}
              </Label>
              <InputGroup className="input-group-alternative">
                <Input
                  className="pl-1 input-field "
                  type="email"
                  disabled={true}
                  defaultValue={email}
                  required
                />
              </InputGroup>
            </FormGroup>

            <FormGroup className="mb-3">
              <Label className="font-weight-bold label">
                {t('auth:firstAndLastNameInputLabel')}
                {'*'}
              </Label>
              <InputGroup className="input-group-alternative">
                <Input
                  placeholder={t('auth:firstAndLastNameInputLabel')}
                  type="text"
                  onChange={(e) => setName(e.target.value)}
                  className="input-field "
                />
              </InputGroup>
            </FormGroup>

            <FormGroup className="mb-3">
              <Label className="font-weight-bold  label">
                {t('global:passwordLabel')}
              </Label>
              <InputGroup className="input-group-alternative">
                <Input
                  onBlur={() => {
                    // Validate on blur
                    setPasswordMeetRequirements(
                      new RegExp(PASSWORD_CHANGE_REGEX).test(password)
                    );
                  }}
                  placeholder={`${t('global:password')}`}
                  type="password"
                  onChange={(e) => {
                    const updatedPassword = e.target.value;
                    setPassword(updatedPassword);
                    isPasswordRequirementsMet(e.target.value);
                  }}
                  className={`input-field ${
                    password && !passwordMeetRequirements
                      ? 'override-border'
                      : ''
                  }`}
                  required
                />
              </InputGroup>
            </FormGroup>

            {showPasswordRequirements && <PasswordRequirements />}

            <FormGroup className="mb-3">
              <Button
                style={{ width: '100%' }}
                disabled={!readyToSend}
                onClick={handleSubmit}
                color={'primary'}
              >
                {t('global:signUp')}
                {loading && (
                  <span className="spinner-border spinner-border-sm" />
                )}
              </Button>
            </FormGroup>
          </Form>
          <PrivacyAndPolicy changeValue={setAcceptTerms} defaultValue={true} />
        </CardBody>
      </Card>
      <Row className="mt-1 text-center" style={{ color: 'white' }}>
        <Col className="col-12">
          <small>
            {t('auth:signUpBasicLoginText')}
            <Link
              style={{ color: 'white' }}
              as={'li'}
              to={`/auth/login/}`}
              className="text-underline"
            >
              {` ${t('global:signIn')}`}
            </Link>
          </small>
        </Col>
      </Row>
    </>
  );
};

export default SignUpMain;
