import { PublicClientApplication } from '@azure/msal-browser';
import NOTIFICATION_TYPES from 'constants/notificationType';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { setFlowsCount } from 'redux/actions/flows.actions';
import { setJustLoggedIn } from 'redux/actions/misc.actions';
import { setOrganization } from 'redux/actions/organization.actions';
import { setOrganizationMembership } from 'redux/actions/organizationMembership.actions';
import { setUser } from 'redux/actions/users.actions';
import { userService } from 'services/user.service';
import { openNotification } from 'utlis/notification';
import { Buffer } from 'buffer';

const msalConfig = {
  auth: {
    clientId: `${process.env.REACT_APP_AZURE_APP_ID}`,
    authority: 'https://login.microsoftonline.com/common',
    redirectUri: `${process.env.REACT_APP_AZURE_REDIRECT_URL}`,
  },
};

// Create an instance of PublicClientApplication
const msalInstance = new PublicClientApplication(msalConfig);

// Initialize the MSAL instance
async function initializeMsal() {
  try {
    await msalInstance.initialize();
    console.log('MSAL initialized successfully');
  } catch (error) {
    console.error('Error initializing MSAL:', error);
  }
}

// Call the initializeMsal function before using any other MSAL API
initializeMsal();

const SSO = ({ mainLayout, redirectUrl, loginType, onResponse }) => {
  const { t, ready } = useTranslation(['auth', 'global', 'notifications']);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Handle the redirect response
    async function handleResponse() {
      try {
        const response = await msalInstance.handleRedirectPromise();
        const currentUrl = window.location.href;
        if (response) {
          setLoading(true);
          const idToken = response.idToken;

          const loginResponse = await userService.microsoftAuthorization(
            idToken,
            loginType,
            currentUrl,
            localStorage.getItem('region')
          );
          if (loginType === 'flowshare') {
            // send back response if its FS authentication
            onResponse(loginResponse);
          } else if (loginType === 'signup') {
            await setUserProfile(loginResponse.accessToken);
            localStorage.removeItem('POR'); //token is removed to available a stay at auth/account-linked
            mainLayout
              ? navigate(redirectUrl, {
                  state: { token: loginResponse.accessToken },
                })
              : window.location.replace(redirectUrl);
          } else if (loginType === 'login') {
            await setUserProfile(loginResponse.accessToken);

            mainLayout
              ? window.location.replace(redirectUrl)
              : window.location.replace(redirectUrl);
          }
        }
      } catch (error) {
        localStorage.clear();
        setLoading(false);

        openNotification(
          t(`notifications:${error.error}`),
          NOTIFICATION_TYPES.ERROR
        );
      }
    }

    async function setUserProfile(token) {
      localStorage.setItem('POR', Buffer.from(token).toString('base64'));
      const userProfile = await userService.getUserProfile();

      dispatch(setUser(userProfile.user));

      dispatch(setOrganization(userProfile.organization));

      dispatch(setOrganizationMembership(userProfile.organizationMembership));

      dispatch(setFlowsCount(userProfile.flowsCount));

      dispatch(setJustLoggedIn(true));
    }

    handleResponse();
  }, []);

  if (!ready) return null;

  const handleLoginSSO = async () => {
    try {
      await msalInstance.loginRedirect({
        scopes: ['openid', 'profile', 'email'],
      });
    } catch (error) {
      console.error('Error during login:', error);
    }
  };

  return (
    <button
      className="btn-microsoft"
      disabled={loading}
      onClick={handleLoginSSO}
      style={{ width: '100%' }}
    >
      <div>
        <img
          src="https://upload.wikimedia.org/wikipedia/commons/4/44/Microsoft_logo.svg"
          alt="Microsoft Logo"
          className="flex items-center justify-center pt-1 pb-1 pr-0"
          width={'47px'}
          height={'29px'}
        />
        <span>{t('auth:microsoftButtonText')}</span>

        {loading && <span className="spinner-border  spinner-border-sm" />}
      </div>
    </button>
  );
};

export default SSO;
