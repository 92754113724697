import { act, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Modal,
  ModalBody,
  Nav,
  NavItem,
  NavLink,
  Button,
  ModalFooter,
  Tooltip,
  Input,
  Row,
  DropdownToggle,
  Dropdown,
  DropdownItem,
  DropdownMenu,
} from 'reactstrap';

import embedWhite from '../assets/img/icons/embed-white.svg';
import linkWhite from '../assets/img/icons/link-white.svg';

import embedBlack from '../assets/img/icons/embed-black.svg';
import linkBlack from '../assets/img/icons/link-black.svg';
import NotificationType from '../constants/notificationType';
import { flowService } from '../services/flow.service';
import { openNotification } from '../utlis/notification';
import { fetchIdFromUr, replaceViewWithEmbed } from '../utlis/utils';

const ShareFlowModal = ({
  isOpen,
  toggleModal,
  url,
  status,
  onUpdateFlowStatus,
}) => {
  const { t, ready } = useTranslation(['flows', 'global']);
  const [activeTab, setActiveTab] = useState('2');
  const [embeddedText, setEmbeddedText] = useState('');
  const [toolTipState, setToolTipState] = useState(false);
  const [embedType, setEmbedType] = useState('responsive');
  const [fixedWidth, setFixedWidth] = useState(500);
  const [fixedHeight, setFixedHeight] = useState(500);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [flowStatus, setFlowStatus] = useState(status);

  const [dropdownLabel, setDropdownLabel] = useState('');

  useEffect(() => {
    setFlowStatus(status);
  }, [status]);

  useEffect(() => {
    if (!ready) return;
    setDropdownLabel(
      status ? t('flows:publicFlowText') : t('flows:privateFlowText')
    );
  }, [status, ready]);

  useEffect(() => {
    setDropdownLabel(
      flowStatus ? t('flows:publicFlowText') : t('flows:privateFlowText')
    );
    if (activeTab === '2') {
      setEmbeddedText(url);
    } else {
      let embedCode;
      if (!flowStatus) {
        setEmbeddedText(t('flows:flowNotEmbeddableText'));
      } else {
        if (embedType === 'responsive') {
          embedCode =
            '<div style="position: relative; padding-bottom: 56.25%; overflow: hidden;"><iframe src="' +
            replaceViewWithEmbed(url) +
            '" style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; border: none;"></iframe></div>';
        } else {
          embedCode =
            '<iframe src="' +
            replaceViewWithEmbed(url) +
            '" width="' +
            fixedWidth +
            '" height="' +
            fixedHeight +
            '" style="border: none;"></iframe>';
        }
        setEmbeddedText(embedCode);
      }
    }
  }, [activeTab, fixedWidth, fixedHeight, embedType, url, flowStatus]);

  const handleSelect = async (newStatus) => {
    const newLabel = newStatus
      ? t('flows:publicFlowText')
      : t('flows:privateFlowText');

    try {
      await flowService.updateFlowMetaInformation(
        newStatus,
        fetchIdFromUr(url)
      );

      setFlowStatus(newStatus);
      setDropdownLabel(newLabel);
      onUpdateFlowStatus(fetchIdFromUr(url), newStatus);
    } catch (e) {
      openNotification(e.error, NotificationType.ERROR);
    }
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };
  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  if (!ready) return null;

  return (
    <div>
      <Modal
        isOpen={isOpen}
        toggle={toggleModal}
        className={'share-flow-modal'}
        centered
      >
        <ModalBody style={{ backgroundColor: 'rgb(249 248 248)' }}>
          <span className="close-button" onClick={toggleModal}>
            &times;
          </span>

          <Tooltip placement="top" isOpen={toolTipState} target={`copyButton`}>
            {t('global:copied')}
          </Tooltip>
          <Nav tabs>
            <NavItem>
              <NavLink
                onClick={() => {
                  toggleTab('2');
                }}
                className={activeTab === '2' ? 'active-tab' : ''}
              >
                <img
                  src={linkBlack}
                  alt="icon"
                  style={{ height: '12px', width: '22px' }}
                />
                {t('flows:shareWithLink')}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={activeTab === '1' ? 'active-tab' : ''}
                onClick={() => {
                  toggleTab('1');
                }}
              >
                <img
                  src={embedBlack}
                  alt="icon"
                  style={{ height: '10px', width: '22px' }}
                />
                {t('global:embed')}
              </NavLink>
            </NavItem>
          </Nav>
          <div className="tab-content mt-3 mb-3">
            {activeTab === '2'
              ? t('flows:shareFlowCopyLinkMessage')
              : t('flows:shareFlowEmbedMessage')}
          </div>

          <div
            className={`flow-embed-box  ${
              !flowStatus && activeTab === '1'
                ? 'flow-embed-box-background-yellow '
                : ''
            }`}
          >
            {embeddedText}
          </div>
        </ModalBody>
        <ModalFooter
          style={{
            justifyContent: activeTab === '1' ? 'space-between' : 'flex-start',
            paddingTop: '0px',
            backgroundColor: 'rgb(249 248 248)',
            flexDirection: activeTab === '2' ? 'column' : 'row',
          }}
        >
          {activeTab === '1' && (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <label>
                <input
                  type="radio"
                  value="responsive"
                  checked={embedType === 'responsive'}
                  onChange={() => setEmbedType('responsive')}
                />
                <i style={{ font: 'inherit', marginLeft: '5px' }}>
                  {t('global:responsive')}
                </i>
              </label>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <label>
                  <input
                    type="radio"
                    value="fixed"
                    checked={embedType === 'fixed'}
                    onChange={() => setEmbedType('fixed')}
                    disabled={!flowStatus}
                  />
                  <i style={{ font: 'inherit', marginLeft: '5px' }}>
                    {t('global:fixed')}
                  </i>
                </label>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginLeft: '10px',
                    marginTop: '-11px',
                  }}
                >
                  <Input
                    type="number"
                    value={fixedWidth}
                    onChange={(e) => setFixedWidth(e.target.value)}
                    placeholder="Width"
                    style={{
                      width: '60px',
                      height: '24px',
                      appearance: 'none',
                    }}
                    disabled={!flowStatus}
                  />
                  <span style={{ margin: '0 5px' }}>x</span>
                  <Input
                    type="number"
                    value={fixedHeight}
                    onChange={(e) => setFixedHeight(e.target.value)}
                    placeholder="Height"
                    style={{ width: '60px', height: '24px' }}
                    disabled={!flowStatus}
                  />
                </div>
              </div>
            </div>
          )}
          <Button
            color="primary"
            id={'copyButton'}
            onClick={() => {
              navigator.clipboard.writeText(embeddedText);
              setToolTipState(true);
            }}
            onMouseLeave={() => {
              setToolTipState(false);
            }}
            style={{ alignSelf: activeTab === '2' ? 'flex-start' : '' }}
            disabled={activeTab === '1' ? !flowStatus : false}
          >
            <img
              src={activeTab === '2' ? linkWhite : embedWhite}
              alt="icon"
              style={{
                height: '12px',
                width: '22px',
                marginRight: '4px',
                color: 'white',
              }}
            />
            {activeTab === '2'
              ? `${t('flows:copyLink')}`
              : `${t('flows:copyEmbedCode')}`}
          </Button>
          <Row
            style={{
              alignSelf: 'flex-start',
              marginLeft: '-3px',
              marginTop: '5px',
            }}
          >
            <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown} size="sm">
              <DropdownToggle
                style={{
                  backgroundColor: 'transparent',
                  boxShadow: 'none',
                }}
                caret
              >
                <i style={{ marginRight: '2px', font: 'inherit' }}>
                  {dropdownLabel}
                </i>
              </DropdownToggle>
              <DropdownMenu>
                {flowStatus && (
                  <DropdownItem onClick={() => handleSelect(!flowStatus)}>
                    {t('flows:privateFlowText')}
                  </DropdownItem>
                )}
                {!flowStatus && (
                  <DropdownItem onClick={() => handleSelect(!flowStatus)}>
                    {t('flows:publicFlowText')}
                  </DropdownItem>
                )}
              </DropdownMenu>
            </Dropdown>
          </Row>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ShareFlowModal;
