import NOTIFICATION_TYPES from 'constants/notificationType';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  List,
  Input,
  FormGroup,
  Label,
} from 'reactstrap';
import { organizationService } from 'services/organization.service';
import { userService } from 'services/user.service';
import { openNotification } from 'utlis/notification';

const DeleteAccount = () => {
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [readyToSend, setReadyToSend] = useState(true);
  const { t, ready } = useTranslation(['profile', 'global', 'notifications']);

  if (!ready) return null;
  const deleteAccount = async () => {
    //TODO delete organization api call
    setLoading(true);
    try {
      await organizationService.deleteOrganization();
      setLoading(false);
      userService.logout(true);
    } catch (e) {
      openNotification(e.error, NOTIFICATION_TYPES.ERROR);
    }
  };
  const toggle = () => {
    setOpenDeleteModal(!openDeleteModal);
  };

  return (
    <>
      <Row className="mt--4">
        <Col className="col-5">
          <Card
            style={{
              border: 'none',
              backgroundColor: 'transparent',
            }}
          >
            <CardHeader
              style={{
                border: 'none',
                backgroundColor: 'transparent',
                paddingLeft: '0px',
              }}
            >
              <h3 className="mb-0"> {t('profile:deleteAccountHeading')}</h3>
            </CardHeader>
            <CardBody className="mt--4" style={{ paddingLeft: '0px' }}>
              <div className="mt--3 pb-0 mb--2">
                {t('profile:deleteAccountText')}
              </div>
            </CardBody>
            <CardFooter
              style={{
                border: 'none',
                backgroundColor: 'transparent',
                paddingLeft: '0px',
                paddingTop: '0px',
              }}
            >
              <Button
                onClick={() => {
                  setOpenDeleteModal(!openDeleteModal);
                }}
                className="btn-danger"
              >
                {t('profile:deleteAccountButtonText')}
              </Button>
            </CardFooter>
          </Card>
        </Col>
      </Row>
      <Modal
        style={{ maxWidth: '600px' }}
        isOpen={openDeleteModal}
        centered
        size="md"
        toggle={toggle}
      >
        <ModalHeader>
          <span
            style={{
              fontSize: '1.5rem',
              fontWeight: 'bold',
              paddingBottom: '0px',
            }}
          >
            {t('profile:deleteAccountModalHeader')}
          </span>
        </ModalHeader>
        <ModalBody
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            paddingTop: '0px',
            paddingLeft: '1.3rem',
          }}
        >
          <div>
            <strong>{t('profile:deleteAccountModalSubTextWarning')}</strong>{' '}
            {''}
            {t('profile:deleteAccountModalSubText')}
          </div>
          <List>
            <li>{t('profile:deleteAccountItemFlows')}</li>
            <li>{t('profile:deleteAccountItemAccess')}</li>
            <li>{t('profile:deleteAccountItemMembers')}</li>
            <li>{t('profile:deleteAccountItemData')}</li>
          </List>
          <div>{t('profile:deleteAccountUninstall')}</div>

          <FormGroup check inline>
            <Input
              onChange={(e) => {
                setReadyToSend(e.target.checked);
              }}
              className="mt-1"
              type="checkbox"
              style={{
                width: '30px',
                height: '30px',
              }}
            />
            <Label check>{t('profile:deleteAccountModalCheckbox')}</Label>
          </FormGroup>
          <div className="d-flex justify-content-end">
            <Button outline onClick={toggle} className="btn-outline">
              {t('global:cancel')}
            </Button>
            <Button
              onClick={deleteAccount}
              className="btn-danger"
              disabled={!readyToSend}
            >
              {t('global:delete')}
              {loading && <span className="spinner-border spinner-border-sm" />}
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default DeleteAccount;
